import { useEffect, useState } from "react";

import { Carousel, Space, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import playerData from "../assets/player.json";
import PlayerImg from "../assets/player.png";
import RankOne from "../assets/rank_i1.png";
import RankTwo from "../assets/rank_i2.png";
import RankThree from "../assets/rank_i3.png";
import {
  PlayerItem,
  RankItem,
  NewSongItem,
  NewSongItemValue,
  RankItemValue,
  PlayerItemType,
} from "./components";
import { PlayerDataItem } from "../player";
import "./index.css";

/** 标题组件 type */
type TitleProps = {
  /** 值 */
  value: string;
};
type RankDataListItem = {
  url: string;
  id: number;
  name: string;
};

function Home() {
  const navigate = useNavigate();
  /** json 数据转 arr */
  const playerArrData = Object.values(playerData) as PlayerDataItem[];
  const [carouselData] = useState([
    "https://static.merach.com/course/20230515/643b78c144bd4c4e890078d38f3491e8.png",
    "https://static.merach.com/course/20230516/3e2569924e5440f48d8ec208d9f2a4ec.jpg",
  ]);
  const [selectedData, setSelectedData] = useState([] as PlayerItemType[]);

  const [rankData, setRankData] = useState([] as RankItemValue[]);
  const [newSongData, setSongData] = useState([] as NewSongItemValue[]);

  /** 处理精选歌单 */
  const formatSelectedData = () => {
    const sliceData = playerArrData.slice(1, 5);

    const data = sliceData.map((item) => {
      return {
        url: item.img,
        id: item.id,
        content: item.name,
      };
    });
    setSelectedData(data);
  };

  /** 处理排行榜单 */
  const formatRankData = () => {
    const rankData = playerArrData.slice(5, 14);
    const rankTemplateData = [
      {
        url: RankOne,
        list: [] as RankDataListItem[],
        activeIndex: 0,
      },
      {
        url: RankTwo,
        list: [] as RankDataListItem[],
        activeIndex: 0,
      },
      {
        url: RankThree,
        list: [] as RankDataListItem[],
        activeIndex: 0,
      },
    ];
    rankData.forEach((item, index) => {
      const idx = Math.floor(index / 3);
      if (rankTemplateData[idx].list?.length < 3) {
        rankTemplateData[idx].list.push({
          name: item.name,
          url: item.img,
          id: item.id,
        });
        rankTemplateData[idx].activeIndex = 0;
      }
    });
    setRankData(rankTemplateData);
  };

  /** 处理新歌数据 */
  const formatSongData = () => {
    const songData = playerArrData.slice(14);
    const data = songData.map((item) => {
      return {
        id: item.id,
        url: item.img,
        author: "",
        name: item.name,
      };
    });
    setSongData(data);
  };

  const onClickSelected = (id?: number) => () => {
    navigate("/player?id=" + (id || 0));
  };
  /** 点击播放 */
  const onClickPlayer = (item?: PlayerItemType) => {
    navigate("/player?id=" + item?.id);
  };

  /** 点击排行榜 */
  const onClickRank = (item?: RankItemValue) => {
    const id = item?.list?.[item?.activeIndex || 0]?.id;
    navigate("/player?id=" + id);
  };

  /** 点击新歌 */
  const onClickSong = (item?: NewSongItemValue) => {
    navigate("/player?id=" + item?.id);
  };

  useEffect(() => {
    formatSelectedData();
    formatRankData();
    formatSongData();
  }, []);
  return (
    <div className="home">
      <div className="home-header">
        <div className="wrap">
          <ul className="m-nav">
            <li className="m-nav-li active-li">发现音乐</li>
          </ul>
        </div>
      </div>
      <div className="home-body">
        <Carousel autoplay>
          {carouselData.map((item, index) => {
            return (
              <img className="carousel-img" src={item} key={index} alt="" />
            );
          })}
        </Carousel>
        <div className="wrap">
          <div className="flex">
            <div>
              <Title value="精选歌单" />
              <div className="flex">
                <div
                  className="flex flex-col mr-2"
                  onClick={onClickSelected(playerData[1].id)}
                >
                  <div className="img-box relative cursor-pointer">
                    <img
                      className="w-80 h-80 rounded-lg"
                      src={playerData[1].img}
                      alt=""
                    />
                    <div className="absolute left-0 top-0 h-full w-full img-mask flex items-center justify-center">
                      <img src={PlayerImg} className="w-12 h-12" alt="" />
                    </div>
                  </div>
                  <span className="text-sm mt-2">{playerData[1].name}</span>
                </div>
                <Space size={8} align="start" className="flex flex-wrap">
                  {selectedData.map((item, index) => {
                    return (
                      <PlayerItem
                        value={item}
                        key={index}
                        onChange={onClickPlayer}
                      />
                    );
                  })}
                </Space>
              </div>
            </div>
            <div className="shrink-0">
              <Title value="热门榜单" />

              {rankData.map((item, index) => (
                <RankItem value={item} key={index} onChange={onClickRank} />
              ))}
            </div>
          </div>
          <div className="pb-10">
            <Title value="新歌首发" />
            <Row gutter={8}>
              {newSongData.map((item, index) => (
                <Col span={8} key={index}>
                  <NewSongItem value={item} onChange={onClickSong} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

function Title(props: TitleProps) {
  const { value } = props;
  return (
    <div className="mb-5 mt-15">
      <h3 className="title">{value}</h3>
    </div>
  );
}

export default Home;
