import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import playerData from "../assets/player.json";
import "./index.css";
export type PlayerDataItem = {
  /** ID */
  id: number;
  /** 音乐地址 */
  url: string;
  /** 名称 */
  name: string;
  /** 图片地址 */
  img: string;
};
export type PlayerData = {
  [key: string]: PlayerDataItem;
};
function Player() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") || "";

  const playerObject = useMemo(() => {
    return (playerData as PlayerData)[id];
  }, [id]);
  console.log(playerObject, "playerObject");

  return (
    <div className="player">
      <div className="player-header">
        <div className="wrap">
          <ul className="m-nav">
            <li className="m-nav-li active-li">发现音乐</li>
          </ul>
        </div>
      </div>
      <div className="player-body">
        <div className="wrap">
          <div className="w-full flex py-2 px-4">
            <img className="w-65 h-65 mr-2" src={playerObject?.img} alt="" />
            <span className="text-2xl text-white">{playerObject?.name}</span>
          </div>
          <audio className="w-full" controls>
            <source type="audio/mpeg" src={playerObject?.url} />
          </audio>
          <div className="blur-bg-mark"></div>
          <div
            className="blur-bg"
            style={{
              backgroundImage: "url(" + playerObject?.img + ")",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Player;
