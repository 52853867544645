import { Routes, Route } from "react-router-dom";
import Home from "./home";
import Player from "./player";
import { Footer } from "./components";
export default function App() {
  return (
    <div className="app overflow-y-auto h-screen">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/player" element={<Player />} />
      </Routes>
      <Footer />
    </div>
  );
}
