import "./index.css";
import Player1Img from "../../../assets/player1.png";

export type NewSongItemValue = {
  /** ID */
  id?: number;
  /** 图片地址 */
  url?: string;
  /** 名称 */
  name?: string;
  /** 作者 */
  author?: string;
};
type NewSongItemProps = {
  value?: NewSongItemValue;
  onChange?: (value?: NewSongItemValue) => void;
};
/** 音乐 item */
export const NewSongItem = (props: NewSongItemProps) => {
  const { value, onChange } = props;
  /** 点击播放音乐 */
  const onClickSong = () => {
    onChange?.(value);
  };
  return (
    <div
      onClick={onClickSong}
      className="flex new-song cursor-pointer items-center justify-between"
    >
      <div className="flex items-center">
        <img className="w-15 h-15 rounded-lg mr-4" src={value?.url} alt="" />
        <div className="flex flex-col">
          <span className="text-sm text-block mb-2">{value?.name}</span>
          <span className="text-xs sub-title">{value?.author}</span>
        </div>
      </div>
      <img src={Player1Img} alt="" />
    </div>
  );
};
