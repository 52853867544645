import "./index.css";
import policeImg from "../../assets/jinghui.png";
export const Footer = () => {
  return (
    <div className="footer">
      <div className="wrap flex items-center justify-center flex-col h-full">
        <div className="flex items-center">
          {/* <a
            className="text-white mr-2 flex items-center"
            target="_blank"
            href="https://beian.mps.gov.cn/#/query/webSearch"
            rel="noreferrer"
          >
            <img src={policeImg} alt="" className="w-4 h-5" />
            浙公网安备33010802010402号
          </a> */}
          <a
            className="text-white"
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            rel="noreferrer"
          >
            浙ICP备2021003090号-1
          </a>
        </div>

        <div>
          <a
            className="text-white mr-2"
            target="_blank"
            href="https://static.merach.com/assets/%E7%89%88%E6%9D%83%E9%9F%B3%E4%B9%90%E6%8E%88%E6%9D%83.pdf"
            rel="noreferrer"
          >
            音乐版权
          </a>

          <span className="text-white mt-2">
            © 2018-2024 浙江御鹿电子科技有限公司 版权所有
          </span>
        </div>
      </div>
    </div>
  );
};
