export type RankItemValueList = {
  /** 附件路径 */
  url?: string;
  /** 附件名称 */
  name?: string;
  id?: number;
};
export type RankItemValue = {
  url?: string;
  list?: RankItemValueList[];
  /** 选中的下标  */
  activeIndex?: number;
};
type RankItemProps = {
  value?: RankItemValue;
  onChange?: (value?: RankItemValue) => void;
};
/** 排行榜 item */
export const RankItem = (props: RankItemProps) => {
  const { value, onChange } = props;
  const onClickRank = (index: number) => () => {
    onChange?.({ ...value, activeIndex: index });
  };

  /** 点击全部排行榜 */
  const onClickAllRank = () => {
    onChange?.(value);
  };
  return (
    <div className="flex items-center mb-2">
      <img
        onClick={onClickAllRank}
        src={value?.url}
        className="w-30 h-30 rounded-lg mr-2"
        alt=""
      />
      <ul className="flex flex-col">
        {value?.list?.map((item, index) => (
          <li
            key={index}
            className="text-sm text-black shrink-0 cursor-pointer mb-1"
            onClick={onClickRank(index)}
          >
            <span>{index + 1}.</span>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
