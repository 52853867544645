import PlayerImg from "../../../assets/player.png";
export type PlayerItemType = {
  url?: string;
  id?: number;
  content?: string;
};
type PlayerItemProps = {
  value?: PlayerItemType;
  onChange?: (value?: PlayerItemType) => void;
};
/** 播放 item */
export const PlayerItem = (props: PlayerItemProps) => {
  const { value, onChange } = props;
  /** 点击图片播放 */
  const onClickImg = () => {
    onChange?.(value);
  };
  return (
    <div className="flex flex-col w-30" onClick={onClickImg}>
      <div className="img-box relative cursor-pointer">
        <img className="w-30 h-30 rounded-lg" src={value?.url} alt="" />
        <div className="absolute left-0 top-0 h-full w-full img-mask flex items-center justify-center">
          <img src={PlayerImg} className="w-10 h-10" alt="" />
        </div>
      </div>

      <span className="text-sm mt-2">{value?.content}</span>
    </div>
  );
};
